/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from "react";
import InputTrigger from "react-input-trigger";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../Image';
import _ from 'lodash';
import {
  UserDropdownContainer, ProfilePicture, ListDropdown, UserContainer, /*InputTextArea,*/ DropDownStyledImage,
  ShowTaggedUser, ShoutImage, Cross, ShoutoutDiv, SelectedImage, ProfileImg, ShowTaggedUser2, InputTextAreaNew,
} from "./styles"; //  ShoutoutContainer, IconContainer,
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';
import { getUsersToShoutout, getCompanyExemplifiesValues } from '../../redux/actions';
import { imgPath, ImageUrl } from '../../utils/constants';
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";

const iSiOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);

class SocialFeedTextarea extends Component {
  constructor() {
    super();
    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      showSuggestor2: false,
      startPosition: null,
      text: null,
      currentSelection: 0,
      employee: [],
      thankYouShoutout: null,
      congratsShoutout: null,
      jobWellDoneShoutout: null,
      textareaValue: '',
      textLength: null,
      trigger: {},
      taggedUser: '',
      showModal: false,
      emojiId: null,
      emojiNative: null,
      emojiSkin: null,
      emojiData: null,
      taggg: ""
    };

    this.backdropRef = React.createRef();

    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const {  employeeData } = this.props;
    if (_.isEmpty(employeeData) && employeeData && employeeData.length === 0) this.fetchUsers();
    this.checkDevice();
    // if (_.isEmpty(exemplifiesValues) && exemplifiesValues && exemplifiesValues.length === 0){getCompanyExemplifiesValues()}
  }

  checkDevice = () => {
    let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (isAndroid) {
      let triggerObj = {};
      triggerObj.keyCode = 229;
      this.setState({
        trigger: triggerObj
      });
    } else {
      let triggerObj = {};
      triggerObj.keyCode = 50;
      triggerObj.shiftKey = !iSiOS;
      this.setState({
        trigger: triggerObj
      })
    }
  };

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.input.value.length === 0 ? 1 : this.input.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }
  toggleSuggestor2(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor2: true,
        left: cursor.left,
        text: "",
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.textarea.value.length === 0 ? 1 : this.textarea.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor2: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  storeFetchedEmployee = () => {
    this.setState({
      employee: this.props.employeeData
    });
  };

  fetchUsers = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.text;
    obj['limit'] = 6;
    if (!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  fetchUsersV2 = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.taggg;
    obj['limit'] = 3;
    if (!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  handleInput(metaInformation) {
    if (metaInformation.text.includes('@')) {
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }
    else {
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }

  }
  handleInput2(metaInformation) {
    if (metaInformation.text.includes('@')) {
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }
    else {
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }

  }
  handleKey(event) {
    const { which } = event;
    const { currentSelection, /*employee*/ } = this.state;
    const {employeeData} = this.props;
    if (_.isNaN(currentSelection)) {
      event.preventDefault();
      this.setState({
        currentSelection: 0
      });
    }

    if (which === 40) {
      event.preventDefault();

      this.setState({
        currentSelection: (currentSelection + 1) % employeeData?.length,
      });
    }

    if (which === 38) {
      event.preventDefault();
      if (currentSelection - 1 < 0)
        this.setState({
          currentSelection: employeeData?.length - 1,
        })
      else
        this.setState({
          currentSelection: (currentSelection - 1) % employeeData?.length,
        });
    }

    if (which === 13 && this.state.showSuggestor && employeeData[currentSelection]) {
      event.preventDefault();

      const { /*employee,*/ currentSelection, textLength, startPosition } = this.state;
      const { inspirationQuote } = this.props;
      const taggedUser = employeeData[currentSelection].fullname.trim();
      const userId = employeeData[currentSelection].uid;
      const newText = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)} ${taggedUser}`;
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        textareaValue: newText,
        currentSelection: 0,
        taggedUser: taggedUser
      }, () => {
        this.props.updateText(newText, userId, taggedUser, true)
      });

      this.endHandler();
    }
  }
  handleInputMsg = (e) => {
    this.setState({
      textareaValue: e.target.value
    })

    this.props.addInspiration(e)


  }
  handleClick = () => {
    const { /*employee,*/ currentSelection } = this.state;

    const { inspirationQuote, employeeData } = this.props;
    let userArray = [...this.props.usersV2];
    const taggedUser = employeeData[currentSelection].fullname.trim();
    if (userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employeeData[currentSelection].uid;
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      // textareaValue: newText,
      currentSelection: 0,
      taggedUser: taggedUser,
      taggg: ''
    }, () => {
      this.props.updateTextV2(inspirationQuote, userId, taggedUser, true);
    });
    // this.endHandler();
  }

  addhightLight = (parameter)=> (
    "<b>" + parameter + "</b>"
  )
  
  handleClickV2 = () => {
    const { /*employee,*/ currentSelection, textLength, startPosition } = this.state;
    const { inspirationQuote, employeeData } = this.props;
    const userArray = [...this.props.users];
    const taggedUser = this.addhightLight(employeeData[currentSelection].fullname).trim();
    if (userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employeeData[currentSelection].uid;
    const newText2 = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)} ${taggedUser}`;
    // const newText = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
    const newText3 = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)} ${taggedUser}`;
    if (typeof this.props.updatePosition === 'function') {
      this.props.updatePosition(taggedUser.length);
    }
    this.setState({
      showSuggestor2: false,
      left: null,
      top: null,
      text: "",
      startPosition: null,
      textareaValue: newText3,
      currentSelection: 0,
      taggedUser: taggedUser
    }, () => {
      this.props.updateText(newText2, userId, null, false);
    });

    this.endHandler2();
    this.checkDevice();
    document.getElementById("inpu").focus();
    // this.inputRef.current.focus();
  }

  handleMouseHover = (index) => {
    this.setState({
      currentSelection: index,
    });
  }

  onScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    if (this.backdropRef && this.backdropRef.current) {
      this.backdropRef.current.scrollTop = scrollTop;
    }
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  }

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.props.addInspirationV2(native);
    document.getElementById("inpu").focus();
    this.inputRef.current.focus();
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.props;
    this.props.updateShoutoutArray(userIdArray, text, image, id);
    id >= 1 && this.changeModal();
  };

  removeShoutout = () => {
    const { shoutoutText, onRemoveUser, onRemoveShoutout } = this.props;
    !shoutoutText ? onRemoveUser() : onRemoveShoutout(shoutoutText)
  };

  handlePeerInput(e) {
    this.setState({
      taggg: e.target.value,
      textLength: e.target.value.length,
      showSuggestor: true,
    },() => this.fetchUsersV2());
  }

  removeBoldTag=(input)=> {
    const regex = input.replace(/<b>/, "").replace(/<\/b>/, "").replace(/>/gi, "").replace(/<b/gi, "")
    return(regex)
  }

  render() {
    const { top, left, showSuggestor, showSuggestor2, /*employee,*/ currentSelection, trigger, taggg } = this.state; // showModal
    const { placeholder, isCommentOrReply, shoutoutText,
      shoutoutImage, taggedUser, enableTrigger, ShowEmojiContainer, exemplifiesValues, selectedImage, wishesAndAnni, className, inspirationQuote, employeeData } = this.props; //  userIdArray
    return (
      <div style={{width:"100%"}}>

        <ShoutoutDiv isCommentOrReply>
          {
            wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? (
              <div>
                <ShowTaggedUser2>
                  <ProfileImg margin={"0px 5px 0 0px"}>
                    <img src={`https://s3.amazonaws.com/images.trainingamigo.com/${this.props.shoutOutData.profile_image}`} />
                  </ProfileImg>
                  {`${this.props.shoutOutData.name ? this.props.shoutOutData.name : ""}`}
                  <Cross src={`${imgPath}/wrong-tic.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser2>
              </div>
            ) : null
          }
          {this.props.showGivenShoutout &&
            <div>
              {_.isNull(shoutoutText) || _.isNull(shoutoutImage) ?
                "" :
                <ShowTaggedUser isCommentOrReply={this.props.isCommentOrReply} >
                  <ShoutImage src={`${ImageUrl}/${shoutoutImage}`} style={{marginTop:"0px"}}/>
                  {/* {` ${shoutoutText} - `}
                  <span style={{ fontWeight: 'bold' }}>{`${taggedUser ? taggedUser : ""} `}</span> */}
                  <b style={{ fontWeight: 'bold', maxWidth: "70% " }}>{` ${shoutoutText} - ${taggedUser ? taggedUser : ""} `}</b>
                  <InputTrigger
                    trigger={trigger}
                    onStart={(metaData) => { enableTrigger && this.toggleSuggestor(metaData); }}
                    onCancel={(metaData) => { enableTrigger && this.toggleSuggestor(metaData); }}
                    onType={(metaData) => { enableTrigger && this.handleInput(metaData); }}
                    endTrigger={(endHandler) => { this.endHandler = endHandler; }}
                  >
                    <input
                      type="text"

                      placeholder={"Tag Who you want Recognize"}
                      rows="1"
                      name=""
                      onChange={(e) => this.handlePeerInput(e)}
                      value={taggg}
                      // value={''}
                      data-gramm_editor="false"
                      className={className ? `tagUserText ${className} ` : 'CustomTextAreaField tagUserText'}
                      onScroll={this.onScroll}
                      spellCheck="false"
                      ref={(node) => this.input = node}
                      style={{ width: "255px"}}
                    />
                  </InputTrigger>
                  <UserContainer
                    showSuggestor={showSuggestor}
                    top={`120px`}
                    left={`${left}px`}
                    width="350px"
                  >
                    {employeeData !== null ? employeeData?.map((user, index) => (
                      <UserDropdownContainer
                        key={index}
                        index={index}
                        onMouseEnter={() => this.handleMouseHover(index)}
                        currentSelection={currentSelection}
                        onClick={this.handleClick}
                      >
                        <ProfilePicture>
                          <DropDownStyledImage width="100%" left height='30px'>
                            <div className="middle">
                              <Image image={user.profile_image} alt={user.fname} />
                            </div>
                            <ListDropdown
                              index={index}
                              currentSelection={currentSelection}
                            >
                              {user.fullname}
                            </ListDropdown>
                          </DropDownStyledImage>
                        </ProfilePicture>
                      </UserDropdownContainer>
                    )) : null}
                  </UserContainer>
                  <img className="cross" onClick={this.removeShoutout} src="/public/images/cross-img.png"></img>
                </ShowTaggedUser>
              }
            </div>
          }
        </ShoutoutDiv>
        {
          selectedImage && selectedImage.length > 0 && <SelectedImage style={{display: 'flex', width:"100%"}}>
            <img src={selectedImage} alt="uploadable" />
            <button style={{height: '20px', marginTop: '10px'}} onClick={this.props.removeImage}><span style={{cursor: 'pointer'}} onClick={this.props.removeImage}>X</span></button>
          </SelectedImage>
        }
        {wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? null : <InputTextAreaNew
          onKeyDown={this.handleKey}
          isCommentOrReply={isCommentOrReply}
          padding={placeholder.includes('reply') ? '13px' : '0'}
          width={placeholder.includes('reply') ? '80%' : '100%'}
        >
          <InputTrigger
            trigger={trigger}
            onStart={(metaData) => { this.toggleSuggestor2(metaData); }}
            onCancel={(metaData) => { this.toggleSuggestor2(metaData); }}
            onType={(metaData) => { this.handleInput2(metaData); }}
            endTrigger={(endHandler) => { this.endHandler2 = endHandler; }}
          >
            <textarea
              placeholder={placeholder}
              rows="15"
              name=""
              // onChange={inspirationQuote?.length<500?(e) => this.props.addInspiration(e): null}
              onChange={(e) => this.props.addInspiration(e)}
              onClick={(e) => this.props.addInspiration(e)}
              value={this.removeBoldTag(inspirationQuote)}
              data-gramm_editor="false"
              className={className ? className : 'CustomTextAreaField'}
              onScroll={this.onScroll}
              spellCheck="false"
              ref={this.inputRef}
              id="inpu"
            >{inspirationQuote}</textarea>
          </InputTrigger>
          <UserContainer
            showSuggestor={showSuggestor2}
            top={`${top}px`}
            left={`${left}px`}
            width="350px"
          >
            {employeeData !== null ? employeeData?.map((user, index) => (
              <UserDropdownContainer
                key={index}
                index={index}
                onMouseEnter={() => this.handleMouseHover(index)}
                currentSelection={currentSelection}
                onClick={this.handleClickV2}

              >
                <ProfilePicture>
                  <DropDownStyledImage width="100%" left height='30px'>
                    <div className="middle">
                      <Image image={user.profile_image} alt={user.fname} />
                    </div>
                    <ListDropdown
                      index={index}
                      currentSelection={currentSelection}
                    >
                      {user.fullname}
                    </ListDropdown>
                  </DropDownStyledImage>
                </ProfilePicture>
              </UserDropdownContainer>
            )) : null}
          </UserContainer>
        </InputTextAreaNew>
        }
        {
          ShowEmojiContainer ?
            <StyledModal
              show={ShowEmojiContainer}
              onHide={this.props.closeEmojiContainer}
              ref={this.props.myref}
            >
              <StyledBody>
                <MainContent>
                  {exemplifiesValues ? exemplifiesValues.map((recognition) => (
                    <PeerButton
                      value={recognition.core_value}
                      onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                      key={recognition.id}
                    >
                      <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'} />
                      <div>{recognition.core_value}</div>
                    </PeerButton>
                  ))
                    : null
                  }
                </MainContent>
              </StyledBody>
            </StyledModal> : null
        }
        {this.props.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.inputRef} hidePicker={this.props.hideEmojiPicker}></SocialFeedsEmojiPicker> : null}
      </div>
    );
  }
}

SocialFeedTextarea.propTypes = {
  employeeData: PropTypes.array,
  fetchUsersToShoutout: PropTypes.func,
  addInspiration: PropTypes.func,
  addInspirationV2: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  inspirationQuote: PropTypes.string,
  className: PropTypes.string,
  updateText: PropTypes.func,
  users: PropTypes.array,
  userIdArray: PropTypes.array,
  placeholder: PropTypes.string,
  updateShoutoutArray: PropTypes.func,
  showGivenShoutout: PropTypes.bool,
  onRemoveShoutout: PropTypes.func,
  isCommentOrReply: PropTypes.string,
  shoutoutImage: PropTypes.string,
  shoutoutText: PropTypes.string,
  taggedUser: PropTypes.string,
  onRemoveUser: PropTypes.func,
  enableTrigger: PropTypes.bool,
  exemplifiesValues: PropTypes.array,
  getCompanyExemplifiesValues: PropTypes.func,
  ShowEmojiContainer: PropTypes.bool,
  myref: PropTypes.object,
  displayEmojiPicker: PropTypes.bool,
  showEmojiPicker: PropTypes.func,
  selectedImage: PropTypes.array,
  shoutOutData: PropTypes.obj,
  wishesAndAnni: PropTypes.number,
  closeEmojiContainer: PropTypes.func,
  emojiRef: PropTypes.object,
  displayEmoji: PropTypes.bool,
  usersV2: PropTypes.array,
  updateTextV2: PropTypes.func,
  hideEmojiPicker: PropTypes.func,
  comments: PropTypes.array,
  removeImage: PropTypes.func,
  // textAreaId:PropTypes.any,
  // selectedImageId:PropTypes.any,
  replyCommentName :PropTypes.string,
  updatePosition: PropTypes.func
};

const mapStateToProps = (state) => ({
  employeeData: state.peopleHome.employeeData,
  exemplifiesValues: state.social.exemplifiesValues
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialFeedTextarea);
